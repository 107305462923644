import React, {useState} from 'react'
import Layout from '../components/Layout'
import { Row, Col } from '../components/Layout/Grid'
import styled, {css} from 'styled-components'
import { media } from '../components/Utilities/Media'
import { colorDarkGray, colorLightGray } from '../components/Variables/Variables'
import CareersData from '../data/careers.json'

import Btn from '../components/Button/Btn'
import { navigate } from '@reach/router'

const CareersStyled = styled.div`
    &::before {
        content: "";
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.75);
        z-index: -1;
        opacity: 0;
        visibility: none;
        transition: .5s all ease-in-out;
        ${props => props.status && css`
            opacity: 1;
            visibility: visible;
            z-index: 1000;
        `};
    }
    .careers-page {
        height: calc(100vh - 105px);
        transition: .5s transform ease-in-out;
        ${props => props.status && css`
            transform: translateX(-25%);
        `};
    }
    .careers-bigImg {
        background:url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80') center 50% no-repeat / cover;
        height: 100%;
        @media ${media.sm} {
            height: 250px;
        }
        @media ${media.lg} {
            background-position: center;
        }
    }

    .careers-prof {
        dt {
            font-size: 13px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        dd {
            cursor: pointer;
            font-size: 17px;
            text-decoration: underline;
            &:not(:first-of-type) {
                margin-top: 13px;
            }
        }
        &:not(:first-of-type) {
            margin-top: 50px;
        }
    }

    .careers-box {
        margin-top: 60px;
    }
`;

const CareersCol = styled(Col)`
    &:first-of-type {
        @media ${media.md} {
            width: 40%;
        }
    }
    &:last-of-type {
        padding: 50px 20px;
        @media ${media.md} {
            padding: 68px;
            width: 60%;
        }
        @media ${media.lg} {
            padding: 120px 68px;
        }
    }
    h1 {
        font-weight: 400;
        letter-spacing: -2px;
        font-size: 60px;
        @media ${media.lg} {
            font-size: 82px;
        }
    }
    h2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -.5px;
        margin-top: 9px;
        color: ${colorDarkGray};
        @media ${media.sm} {
            font-size: 36px;
        }
    }
`

const ModalStyled = styled.div`
    transform: translateX(100%);
    position: fixed;
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    z-index: 2000;
    transition: .5s transform ease-in-out;
    ${props => props.showModal && css`
        transform: translateX(0);
    `}

    .md__close {
        width: 30px;
        cursor: pointer;
        float: right;
        margin: 20px 20px 20px 0;
    }
`

const ModalThumbnail = styled.div`
    clear: right;
    background: url(${props => props.thumbnail}) center/cover no-repeat;
    height: 250px;
    @media ${media.lg} {
        height: 400px;
    }
`
const ModalDescription = styled.div`
    padding: 45px 30px;
    .md__header {
        position: relative;
        border-bottom: 1px solid ${colorLightGray};
        padding-bottom: 20px;
        h3 {
            font-size: 22px;
            margin-bottom: 9px;
        }
        #md__apply {
            position: absolute;
            top:0;
            right: 0;
        }
    }
    .md__body {
        margin-top: 30px;
        h4 {
            font-size: 18px;
            margin-bottom: 9px;
            &:not(:first-of-type) {
                margin-top: 45px;
            }
        }
        p {
            line-height: 1.75;
        }
    }
`

const CareersModal = (props) => {
    const hideModal = () => {
        props.callBack(false);
    }
    const redirectLink = (id) => {
        navigate(`/application`);
    }
    return (
       <ModalStyled showModal={props.status}>
           <img src={require('../images/close.png')} className="md__close" onClick={hideModal}/>
           <ModalThumbnail thumbnail={props.thumbnail}/>
           <ModalDescription>
               <div className="md__header">
                <h3>{props.title}</h3>
                <p>Place | Salary Range | Full Time</p>
                <a id="md__apply" onClick={() => redirectLink(props.id)}><Btn primary>Apply</Btn></a>
               </div>
               <div className="md__body">
                <h4>Job Description</h4>
                <p>{props.description}</p>
                <h4>Minimum Qualifications</h4>
                <p>{props.requirements}</p>
               </div>
           </ModalDescription>
       </ModalStyled>
    )
}

const Careers = () => {
    const [modal, setModal] = useState(false);
    const [data, setData] = useState(null);
    const activateModal = (id, title, desc, req, tb, link) => {
        setModal(true);
        setData({
            ...data,
            id: id,
            title: title,
            description: desc,
            requirements: req,
            thumbnail: tb,
            link: link
        })
    }

    const parentCallback = (value) => {
        setModal(value);
    }

    return (
       <Layout>
           <CareersStyled status={modal}>
            <CareersModal status={modal} {...data} callBack={parentCallback}/>
            <Row cname="careers-page">
                <CareersCol>
                        <div className="careers-bigImg"></div>
                </CareersCol>
                <CareersCol>
                        <h1>Work With Us</h1>
                        <h2>Interested in joining our team? See our open jobs below</h2>
                        <div className="careers-box">
                            <dl className="careers-prof">
                                <dt>Engineering Team</dt>
                                {
                                    CareersData.careers.map(({id, job_content : {job_title, job_description, job_requirements, thumbnail, job_link} }) => (
                                        <dd key={id} onClick={() => activateModal(id, job_title, job_description, job_requirements, thumbnail,job_link)}>{job_title}</dd>
                                    ))
                                }
                            </dl>
                        </div>
                </CareersCol>
            </Row>
           </CareersStyled>
       </Layout>
    )
}

export default Careers
